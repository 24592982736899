"use strict";

import { InitializeMap, AddMarkers } from "./mapbox";
import { ClosestCareLocation } from "./closest-care-location";
import { determineCurrentSite, initMapCoords } from "./utils";

export class HomePage {
	constructor() {
		this.container = document.querySelector("section.homepage-container");
		if (!this.container) {
			return;
		}

		/* const iamLookingSelect = new MDCSelect(document.querySelector("#iam-looking"));
        const iamTypeSelect = new MDCSelect(document.querySelector("#iam-type"));

        const foundation = iamLookingSelect.getDefaultFoundation();
        const adapter = foundation.adapter_;

        iamTypeSelect.listen("MDCSelect:change", () => {
            const li = document.querySelectorAll("#iam-looking .mdc-list li");
            let selectedElementIndex = -1;
            let firstElementIndex = -1;

            let currentElement = adapter.getSelectedMenuItem();

            li.forEach((el, index) => {
                el.classList.remove("mdc-list-item--selected");
                if (el.getAttribute('data-parent-id') == iamTypeSelect.value) {
                    el.classList.remove("hidden");

                    if (firstElementIndex == -1) {
                        firstElementIndex = index;
                    }

                    if (currentElement.textContent === el.textContent) {
                        selectedElementIndex = index;
                    }
                } else {
                    el.classList.add("hidden");
                }
            });

            const desiredElementIndex = selectedElementIndex == -1 ? firstElementIndex : selectedElementIndex;

            foundation.setSelectedIndex(desiredElementIndex);
            adapter.focusMenuItemAtIndex(desiredElementIndex);

            currentElement = adapter.getSelectedMenuItem();
            currentElement.click();
        });

        const btn = document.querySelector("#iam-button-action");
        btn.addEventListener("click", (e) => {
            e.preventDefault();
            const el = adapter.getSelectedMenuItem();
            const link = el.getAttribute("data-url");
            const newWindow = el.getAttribute("data-newwindow");

            if (newWindow == "") {
                window.location = link;
            } else {
                window.open(link);
            }
        }); */
		this.currentSite = determineCurrentSite();

		this.ajaxCalled = false;

		this.map = InitializeMap(
			document.querySelector("#map"),
			initMapCoords[this.currentSite],
			this.onGeolocateCallback.bind(this),
			".information-container .information"
		);

		this.gatherMarkers();
	}

	onGeolocateCallback(geoCoords) {
		if (this.ajaxCalled == false) {
			this.ajaxCalled = true;

			if (geoCoords) {
				new ClosestCareLocation(geoCoords, this.map);
			}
		}
	}

	/**
	 * Update the map with dynamic markers showing all parent/physical locations
	 */
	async gatherMarkers() {
		try {
			const response = await fetch("/location-markers", {
				method: "GET",
				mode: "same-origin",
				cache: "no-cache",
				follow: true,
				async: true,
				credentials: "same-origin",
				headers: {
					Accept: "text/html",
					"x-isAjax": true,
				},
			});
			const { locations } = await response.json();
			const locationMarkers = [];
			locations.forEach((location) => {
				locationMarkers.push({
					type: "Feature",
					geometry: {
						type: "Point",
						coordinates: [location.longitude, location.latitude],
					},
					properties: {
						name: location.city,
						address: location.address,
					},
				});
			});
			const markers = {
				type: "FeatureCollection",
				features: locationMarkers,
			};
			AddMarkers(markers, this.map, false);
			this.map.resize();
		} catch (error) {
			console.log("error loading location markers for map", error);
		}
	}
}
