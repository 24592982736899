'use strict';
import lottie from 'lottie-web';
import '../json/Duly_loader_strokestofill.json';
import { AsYouType } from 'libphonenumber-js';

/**
 * Prevents tabbing outside of the provided list of elements.
 * 
 * @param {*} focusableElementsSelectorString 
 */
export const enableFocusTrap = (focusableElementsSelectorString) => {
    let focusable = document.querySelectorAll(focusableElementsSelectorString);
    if (focusable.length) {
        focusable[0].focus();
    }

    window.addEventListener('keydown', e => {
        if (e.keyCode === 9) {
            focusable = document.querySelectorAll(focusableElementsSelectorString);
            if (focusable.length) {
                let first = focusable[0];
                let last = focusable[focusable.length - 1];
                let shift = e.shiftKey;
                if (shift) {
                    if (e.target === first) { // shift-tab pressed on first input in dialog
                        last.focus();
                        e.preventDefault();
                    }
                } else {
                    if (e.target === last) { // tab pressed on last input in dialog
                        first.focus();
                        e.preventDefault();
                    }
                }
            }
        }
    });
};

export const initLottie = () => {
    document.querySelectorAll(".lottie").forEach(element => {
        if (element.lottieInitialized) {
            return;
        }

        let path = null;

        // if CDN is used, we need to remove "/dist" and use the CDN domain
        const mainJSBundleScriptTag = document.querySelector("#main-js-bundle");
        const distCdnUrl = mainJSBundleScriptTag.dataset.distCdnUrl ? mainJSBundleScriptTag.dataset.distCdnUrl : null;
        const distMountPoint = mainJSBundleScriptTag.dataset.distMountPoint ? mainJSBundleScriptTag.dataset.distMountPoint : null;
        if (distCdnUrl !== null || distMountPoint !== null) {
            path = `${distCdnUrl}${distMountPoint}Duly_loader_strokestofill.json`;
            path = path.replace("/dist", "/dist");
        } else {
            path = "/Duly_loader_strokestofill.json";
        }

        lottie.loadAnimation({
            container: element, // Required
            path: path, // Required
            loop: true, // Optional
            autoplay: true // Optional
        });

        element.lottieInitialized = true;
    });
}

export const setupPhoneHandler = (input) => {
    ['keyup', 'focus', 'blur', 'paste'].forEach((event) => {
        input.addEventListener(event, (e) => {
            const formattedNumber = new AsYouType('US').input(e.target.value);
            e.target.value = formattedNumber;
        });
    });
}

/**
 * Returns an identifier corresponding to the current site we are on. 
 * @returns "dmg"|"sbc"|"unknown"
 */
export const determineCurrentSite = () => {
    let el = document.querySelector('.main-footer input[name="current-site-handle"]');
    if (!el || !el.value) return "unknown";
    if (el.value === "default") return "dmg";
    if (el.value === "southBendClinic") return "sbc";
    if (el.value === "qmg") return "qmg";
    else return "unknown";
}

/**
 * default coords for initializing map for each site
 */
export const initMapCoords = {
    dmg: [-88.014710, 41.831440],
    sbc: [-86.2569242, 41.6748274],
    qmg: [-91.4013785, 39.9322798],
}

export const handleLocationCardViewAllHours = () => {
    const viewAllHours = document.querySelectorAll('.locationCard .viewAllHours,.nearestLocations');
    viewAllHours.forEach((viewAll) => {
        const toggle = viewAll.querySelector('.toggle');
        toggle.addEventListener('click', () => {
            viewAll.classList.toggle('active');
        })
    });
}

export const setupDateInputListeners = (monthInput, dayInput, yearInput, onInput = () => void 0) => {
    monthInput.addEventListener("input", (e) => {
        e.preventDefault();
        const { target } = e;
        target.value = target.value.replace(/\D/g, "");
        onInput();
        // skip to the day field
        if (target.value.length === 2) {
            dayInput.select();
        }
    });

    dayInput.addEventListener("input", (e) => {
        e.preventDefault();
        const { target } = e;
        target.value = target.value.replace(/\D/g, "");
        onInput();
        // skip to the year field
        if (target.value.length === 2) {
            yearInput.select();
        }
    });

    yearInput.addEventListener("input", (e) => {
        e.preventDefault();
        const { target } = e;
        target.value = target.value.replace(/\D/g, "");
        onInput();
    });
}

export const handleTableScroll = () => {
    const tableContainers = document.querySelectorAll(".table-container");
    if (!tableContainers) {
        return;
    }
    const handleShowScrollViewMore = () => {
        tableContainers.forEach((container) => {
        const table = container.querySelector("table");
        if (!table) {
            return;
        }
        const scrollViewMore = container.nextElementSibling;
        if (container.offsetWidth < table.offsetWidth) {
            scrollViewMore.classList.remove("hidden");
        } else {
            scrollViewMore.classList.add("hidden");
        }
        });
    };
    handleShowScrollViewMore();
    window.addEventListener("resize", () => {
        handleShowScrollViewMore();
    });
};
  